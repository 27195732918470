import React, { useState, useContext } from "react";
import Header from '../../section/header'
import Footer from '../../section/footer'
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import { Link } from 'react-router-dom';
import { PageContext } from "../../App";
import PageCTA from "../../section/page-cta";

const HowItWork = () => {
  const pageData = useContext(PageContext);
  const HowItWorkData = pageData.how_it_works ? JSON.parse(pageData.how_it_works) : { heading: '', content: { text: '' } };
  const { heading, content } = HowItWorkData;
  return (
    <>
  <Header/>
  <div className="content_page_wrapper">
        <div className="container">
    <ul className="page_breadcrumb">
          <li><Link to="/">Home </Link></li>
          <li><Icon path={mdiChevronRight} size={1} /> How It Works </li>
        </ul> 
        <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: content.text }} />
        </div>
        </div>
    <PageCTA/>
  <Footer/>
    </>
  )
}

export default HowItWork