import React from "react";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
const RequestBtn = (props) => {
  const { handleClick, ctaText, ctaSubText, ctaStyle, setIsHover } = props;
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <div className="req_main">
      <button
          style={ctaStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="request_btn" 
          onClick={handleClick}
        >
          <span className="submit_underline"> {ctaText}</span> -{" "}
          <span className="submit_blur">{ctaSubText}</span>{" "}
          <Icon path={mdiPlay} size={0.9} />
          </button>
      </div>
    </>
  );
};

export default RequestBtn;
