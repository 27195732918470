import React, { useContext, useState } from "react";
import { HomeContext } from "../../App";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import RequestBtn from "../../component/RequestBtn/RequestBtn";

const PageCTA = () => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const ctaSectionContent = data.customization.ctaSection.content;
  const ctaSectionSty = data.customization.ctaSection.style;
  const handleClick = ({ onButtonClick }) => {
    onButtonClick();
  };
  const CtaHeadingColor = {
    color: ctaSectionSty.headingColor,
  };
  const CtaSubHeadingColor = {
    color: ctaSectionSty.subHeadingColor,
  };
  const gradColor1 = ctaSectionSty.gradColor1;
  const gradColor2 = ctaSectionSty.gradColor2;
  const bgColor = {
    background: ctaSectionSty.backgroundColor
      ? ctaSectionSty.backgroundColor
      : `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
  };
  const ctaStyle = {
    backgroundColor: isHover ? ctaSectionSty.ctaHoverBg : ctaSectionSty.ctaBg,
    color: isHover
      ? ctaSectionSty.ctaHoverTextColor
      : ctaSectionSty.ctaTextColor,
  };
  return (
    <div className="page_cta_wrapper" style={bgColor}>
      <div className="container">
        <h3
          style={CtaHeadingColor}
          dangerouslySetInnerHTML={{
            __html: ctaSectionContent.headingText,
          }}
        />
        <p
          style={CtaSubHeadingColor}
          dangerouslySetInnerHTML={{
            __html: ctaSectionContent.subheadingText,
          }}
        />
        <a className="request_btn" href="/" style={ctaStyle}>
          <span className="submit_underline">Request Now</span> -{" "}
          <span className="submit_blur">It's free </span>{" "}
          <Icon path={mdiPlay} size={0.9} />
        </a>
      </div>
    </div>
  );
};

export default PageCTA;
