import React, { useRef, useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiHomeOutline } from "@mdi/js";
import { mdiCreditCardRefreshOutline } from "@mdi/js";
import { mdiCarWrench } from "@mdi/js";
import { mdiHospitalBoxOutline } from "@mdi/js";
import { mdiAirplane } from "@mdi/js";
import { mdiCubeOutline } from "@mdi/js";
import { HomeContext } from "../../App";

const Request = () => {
  const data = useContext(HomeContext);
  const requestSectionContent = data.customization.requestSection.content;
  const requestSectionSty = data.customization.requestSection.style;

  const RequestHeadingColor = {
    color: requestSectionSty.headingColor,
  };
  const RequestSubHeadingColor = {
    color: requestSectionSty.subHeadingColor,
  };
  const IconColor = {
    color: requestSectionSty.iconColor,
  };
  const IconTextColor = {
    color: requestSectionSty.iconTextColor,
  };
  const gradColor1 = requestSectionSty.gradColor1;
  const gradColor2 = requestSectionSty.gradColor2;

  const bgColor = {
    background: requestSectionSty.backgroundColor
      ? requestSectionSty.backgroundColor
      : `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
  };
  const requestItemBoxShadow = {
    boxShadow: `0px 2px 4px 0px ${requestSectionSty.boxShadowColor1}, 0px 4px 12px 0px  ${requestSectionSty.boxShadowColor2}`,
  };
  return (
    <div className="request_loan_wrapper" style={bgColor}>
      <div className="container">
        <div
          className={`row align-items-lg-center ${
            requestSectionContent.requestSectionFlip === "Yes"
              ? "flex-row-reverse"
              : ""
          }`}
        >
          <div className="col-lg-6">
            <div className="request_loan_content">
              <h3
                style={RequestHeadingColor}
                dangerouslySetInnerHTML={{
                  __html: requestSectionContent.headingText,
                }}
              />
              <p
                style={RequestSubHeadingColor}
                dangerouslySetInnerHTML={{
                  __html: requestSectionContent.subheadingText,
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="request_loan_box">
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon1text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiCreditCardRefreshOutline}
                  size={1.5}
                />
              </a>
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon2text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiHomeOutline}
                  size={1.5}
                />
              </a>
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon3text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiCarWrench}
                  size={1.5}
                />
              </a>
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon4text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiHospitalBoxOutline}
                  size={1.5}
                />
              </a>
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon5text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiAirplane}
                  size={1.5}
                />
              </a>
              <a
                href="#"
                className="request_loan_item"
                style={requestItemBoxShadow}
              >
                <h6 style={IconTextColor}>{requestSectionContent.icon6text}</h6>
                <Icon
                  style={{ color: requestSectionSty.iconColor }}
                  path={mdiCubeOutline}
                  size={1.5}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
