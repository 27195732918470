import React, { useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import { HomeContext } from "../../App";
import RequestBtn from "../../component/RequestBtn/RequestBtn";

const User = ({ onButtonClick }) => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const userSectionContent = data?.customization?.userSection?.content;
  const userSectionSty = data?.customization?.userSection?.style;
  const userImage = data?.section1_img;

  const ctaStyle = {
    backgroundColor: isHover ? userSectionSty.ctaHoverBg : userSectionSty.ctaBg,
    color: isHover
      ? userSectionSty.ctaHoverTextColor
      : userSectionSty.ctaTextColor,
  };
  const handleClick = () => {
    onButtonClick();
  };
  return (
    <div className="page_user_wrapper">
      <div className="container">
        <div
          className={`row align-items-lg-center ${
            userSectionContent.userSectionFlip === "Yes"
              ? "flex-row-reverse"
              : ""
          }`}
        >
          <div className="col-lg-6">
            <div className="page_user_image">
              <img src={`static/assets/images/${userImage}`} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="page_user_content">
              <h3
                dangerouslySetInnerHTML={{
                  __html: userSectionContent.headingText,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: userSectionContent.subheadingText,
                }}
              />
              <RequestBtn
                handleClick={handleClick}
                ctaText={userSectionContent.ctaText}
                ctaSubText={userSectionContent.ctaSubText}
                ctaStyle={ctaStyle}
                setIsHover={setIsHover}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
