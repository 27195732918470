import React, { useState, useContext } from "react";
import Header from "../../section/header";
import Footer from "../../section/footer";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { Link } from "react-router-dom";
import PageCTA from "../../section/page-cta";
import { PageContext } from "../../App";
import ContactForm from "../../component/form/ContactForm";

const ContactUs = () => {
  const pageData = useContext(PageContext);
  const contactData = pageData.contact ? JSON.parse(pageData.contact) : { heading: '', content: { text: '' } };
  const { heading, content } = contactData;
  return (
    <>
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> Contact Us
            </li>
          </ul>
          <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: content.text }} />
          <ContactForm />
        </div>
      </div>
      <PageCTA />
      <Footer />
    </>
  );
};

export default ContactUs;
