import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../App";
import Accordion from "react-bootstrap/Accordion";

const FAQ = () => {
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isHoverMore, setIsHoverMore] = useState(false);
  const faqSectionContent = data.customization.faq.content;
  const faqSectionSty = data.customization.faq.style;
  const FAQHeadingColor = {
    color: faqSectionSty.mainHeadingColor,
  };
  const activeQus = {
    color: faqSectionSty.themeColor2,
  };
  const borderSty = {
    // borderBottom: "1px solid",
    // borderColor: isHover
    //   ? faqSectionSty.themeColor2
    //   : faqSectionSty.paraTextColor,
  };

  const handleMouseEnter = (index) => {
    setIsHover(index + 1);
  };

  const handleMouseLeave = (index) => {
    if (index !== "notActive") {
      setIsHover(0);
    }
  };

  const faqBackground = {
    backgroundColor: faqSectionSty.backgroundColor
      ? faqSectionSty.backgroundColor
      : "",
  };

  const sectionTitle = {
    color: faqSectionSty.titleColor ? faqSectionSty.titleColor : "",
  };

  const quesText = {
    backgroundColor: faqSectionSty.quesbarbackground
      ? faqSectionSty.quesbarbackground
      : "",
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const activeTextQus = {
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const answerbarStyle = {
    backgroundColor: faqSectionSty.answerbarbackground
      ? faqSectionSty.answerbarbackground
      : "",
    color: faqSectionSty.paraTextColor ? faqSectionSty.paraTextColor : "",
  };
  const mySty = {
    ".faq_block .accordion-button::after": {
      backgroundImage:
        "url(http://localhost:3001/static/media/faq_arrow2.f3fd1be….svg)",
      transform: "rotate(-90deg)",
    },
  };

  const handelActive = (index) => {
    setIsActive(index + 1);
  };
  return (
    <div className="faq_wrapper">
      <div className="container">
        <div
          className={`row align-items-lg-center ${
            faqSectionContent.FAQSectionFlip === "Yes" ? "flex-row-reverse" : ""
          }`}
        >
          <div className="col-lg-6">
            <div className="faq_head">
              <h3
                style={FAQHeadingColor}
                dangerouslySetInnerHTML={{
                  __html: faqSectionContent.titleText,
                }}
              />
              <h3>{}</h3>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq_content">
              <Accordion defaultActiveKey="0">
                {faqSectionContent.faq.map((item, index) => (
                  <Accordion.Item
                    key={item.id}
                    eventKey={item.id}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={
                      isActive === index + 1
                        ? () => handleMouseLeave("notActive")
                        : () => handleMouseLeave(index)
                    }
                    style={
                      isHover === index + 1 || isActive === index + 1
                        ? borderSty
                        : {}
                    }
                    onClick={() => handelActive(index)}
                  >
                    <Accordion.Header
                      className="qus_txt"
                      style={(activeTextQus, mySty)}
                    >
                      <p
                        style={
                          isActive === index + 1
                            ? activeQus
                            : {} && activeTextQus
                        }
                      >
                        {" "}
                        {item.qus}{" "}
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p
                        style={answerbarStyle}
                        className="ans_txt"
                        dangerouslySetInnerHTML={{ __html: item.ans }}
                      ></p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
