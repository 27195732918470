import React from "react";
import { useContext, useState, useEffect } from "react";
import API from "../../service/Api";
import { Navbar, Nav } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { HomeContext, PageContext } from "../../App";
import { Link } from "react-router-dom";

const Header = () => {
  const data = useContext(HomeContext);
  const pageData = useContext(PageContext);

  const headerSectionContent = data?.customization?.headerSection?.content;
  const headerSectionSty = data?.customization?.headerSection?.style;

  const gradColor1 = headerSectionSty.gradColor1;
  const gradColor2 = headerSectionSty.gradColor2;

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const logo = data.logo_img;
  const navlinkColor = {
    color: headerSectionSty.linkColor,
  };
  const headerBg = {
    background: isNavCollapsed
      ? headerSectionSty.backgroundColor
      : `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
    //background: `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
  };
  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos < prevScrollPos) {
        // Scrolling up, add class
        document
          .querySelector(".navbar.navbar-light.fixed-top")
          .classList.add("active_div");
      } else {
        // Scrolling down, remove class
        if (window.scrollY > 200) {
          document
            .querySelector(".navbar.navbar-light.fixed-top")
            .classList.remove("active_div");
        }
      }

      // Update previous scroll position
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className="header" id="Header">
      <nav
        className="navbar navbar-light fixed-top active_div"
        style={headerBg}
      >
        <div className="container">
          <Link to="/">
            {" "}
            <img src={`static/assets/images/${logo}`} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <input type="checkbox" id="navcheck" role="button" title="menu" />
            <label
              for="navcheck"
              aria-hidden="true"
              title="menu"
              onClick={() => setIsNavCollapsed(!isNavCollapsed)}
            >
              <span className="burger">
                <span className="bar">
                  <span className="visuallyhidden">Menu</span>
                </span>
              </span>
            </label>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link
                  to="/how-it-works"
                  style={navlinkColor}
                  onMouseEnter={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkHoverColor;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkColor;
                  }}
                >
                  How It Works
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/contact-us"
                  style={navlinkColor}
                  onMouseEnter={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkHoverColor;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkColor;
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/disclaimer"
                  style={navlinkColor}
                  onMouseEnter={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkHoverColor;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkColor;
                  }}
                >
                  Disclaimer
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/rate-and-fees"
                  style={navlinkColor}
                  onMouseEnter={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkHoverColor;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style = "color:" + headerSectionSty.linkColor;
                  }}
                >
                  About Rates & <br /> Fees
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
