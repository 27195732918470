import React, { useRef } from "react";
import Header from "../../section/header";
import Footer from "../../section/footer";
import Banner from "../../section/banner";
import User from "../../section/user";
import Request from "../../section/request";
import Finding from "../../section/finding";
import FAQ from "../../section/faq";
import HomeCTA from "../../section/home-cta";

const Home = (props) => {
  const bannerRef = useRef(null);
  const handleButtonClick = () => {
    bannerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Header />
      <Banner ref={bannerRef} GaTrack={props.GaTrack} />
      <User onButtonClick={handleButtonClick} />
      <Request onButtonClick={handleButtonClick} />
      <Finding onButtonClick={handleButtonClick} />
      <FAQ />
      <HomeCTA onButtonClick={handleButtonClick} />
      <Footer />
    </div>
  );
};
export default Home;
