import React, { useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiSquareEditOutline } from "@mdi/js";
import { mdiTimerSandComplete } from "@mdi/js";
import { mdiAccountMultiple } from "@mdi/js";
import { mdiShieldCheckOutline } from "@mdi/js";
import { TypeAnimation } from "react-type-animation";
import BannerForm from "../bannerForm";
import { HomeContext } from "../../App";

const Banner = React.forwardRef((props, ref) => {
  const data = useContext(HomeContext);

  const bannerSectionContent = data.customization.bannerSection.content;
  const bannerSectionSty = data.customization.bannerSection.style;

  const whyUsSectionContent = data.customization.whyUsSection.content;
  const whyUsSectionSty = data.customization.whyUsSection.style;

  const gradColor1 = bannerSectionSty.gradColor1;
  const gradColor2 = bannerSectionSty.gradColor2;

  const bgColor = {
    background: bannerSectionSty.backgroundColor
      ? bannerSectionSty.backgroundColor
      : `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
  };
  const formBoxShadow = {
    boxShadow: `0px 4px 14px 0px ${bannerSectionSty.formShadowColor}`,
  };
  const subHeadingColor = {
    color: bannerSectionSty.subHeadingColor,
  };
  const HeadingColor = {
    color: bannerSectionSty.HeadingColor,
  };
  const WhyHeadingColor = {
    color: whyUsSectionSty.titleColor,
  };
  const WhyIconColor = {
    color: whyUsSectionSty.iconColor,
  };
  const WhyBoldColor = {
    color: whyUsSectionSty.boldTextColor,
  };
  const WhyParaColor = {
    color: whyUsSectionSty.paraTextColor,
  };

  return (
    <>
      <div className="page_banner_wrapper" style={bgColor} ref={ref}>
        <div className="container">
          <div
            className={`row align-items-lg-center ${
              bannerSectionContent.bannerSectionFlip === "Yes"
                ? "flex-row-reverse"
                : ""
            }`}
          >
            <div className="col-lg-6 form_grid">
              <div className="page_banner_form" style={formBoxShadow}>
                <h5 style={subHeadingColor}>All credit type welcome</h5>
                <h1 style={HeadingColor}>
                  {" "}
                  Request a loan for <br />
                  <TypeAnimation
                    data-aos="fade-up"
                    sequence={[
                      "Debt Consolidation",
                      2000,
                      "Auto Purchase",
                      2000,
                      "Home Improvement",
                      2000,
                      "Moving/Traveling",
                      2000,
                      "Small Business",
                      2000,
                      "Wedding",
                      2000,
                      "Vacation",
                      2000,
                      "",
                    ]}
                    speed={10}
                    wrapper="span"
                    style={{ color: bannerSectionSty.HeadingAnimColor }}
                    repeat={Infinity}
                    className="text-yellow-500 text-4xl font-bold sm:text-3xl"
                  />
                </h1>
                <div className="FormBlock" ref={ref}>
                  <BannerForm GaTrack={props.GaTrack} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="page_banner_content">
                <h3
                  style={WhyHeadingColor}
                  dangerouslySetInnerHTML={{
                    __html: whyUsSectionContent.titleText,
                  }}
                />
                <h3>{}</h3>
                <div className="why_box_wrapper">
                  <div className="why_box_item">
                    <Icon
                      style={WhyIconColor}
                      path={mdiSquareEditOutline}
                      size={1.5}
                    />
                    <h6 style={WhyBoldColor}>
                      {whyUsSectionContent.icon1bold}
                    </h6>
                    <p style={WhyParaColor}>{whyUsSectionContent.icon1text}</p>
                  </div>
                  <div className="why_box_item">
                    <Icon
                      style={{ color: whyUsSectionSty.iconColor }}
                      path={mdiTimerSandComplete}
                      size={1.5}
                    />
                    <h6>{whyUsSectionContent.icon2bold}</h6>
                    <p>{whyUsSectionContent.icon2text}</p>
                  </div>
                  <div className="why_box_item">
                    <Icon
                      style={{ color: whyUsSectionSty.iconColor }}
                      path={mdiAccountMultiple}
                      size={1.5}
                    />
                    <h6>{whyUsSectionContent.icon3bold}</h6>
                    <p>{whyUsSectionContent.icon3text}</p>
                  </div>
                  <div className="why_box_item">
                    <Icon
                      style={{ color: whyUsSectionSty.iconColor }}
                      path={mdiShieldCheckOutline}
                      size={1.5}
                    />
                    <h6>{whyUsSectionContent.icon4bold}</h6>
                    <p>{whyUsSectionContent.icon4text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Banner;
