import React, { useState, useContext } from "react";
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import Header from '../../section/header'
import Footer from '../../section/footer'
import { Link } from 'react-router-dom';
import { PageContext } from "../../App";
import PageCTA from '../../section/page-cta';

const Terms = () => {
  const pageData = useContext(PageContext);
  const termsData = pageData.terms ? JSON.parse(pageData.terms) : { heading: '', content: { text: '' } };
  const { heading, content } = termsData;
  return (
    <>
    <Header/>
    <div className="content_page_wrapper">
        <div className="container">
    <ul className="page_breadcrumb">
          <li><Link to="/">Home </Link></li>
          <li><Icon path={mdiChevronRight} size={1} /> Terms Of Services </li>
        </ul> 
        <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: content.text }} />
        </div>
        </div>
    <PageCTA/>
    <Footer/>
      </>
  )
}

export default Terms