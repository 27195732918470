import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HomeContext } from "../../App";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";

const Footer = () => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const footerSectionContent = data.customization.footer.content;
  const footerSectionSty = data.customization.footer.style;
  const logo = footerSectionContent.footerLogo;

  const top_footer_style = {
    color: footerSectionSty.footerParaColor,
    background: footerSectionSty.backgroundColor,
  };
  const bottom_footer_style = {
    color: footerSectionSty.bottomParaTextColor,
    background: footerSectionSty.foterBottomBg,
  };
  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }

  const ctaStyle = {
    backgroundColor: isHover
      ? footerSectionSty.ctaHoverBg
      : footerSectionSty.ctaBg,
    color: isHover
      ? footerSectionSty.ctaHoverTextColor
      : footerSectionSty.ctaTextColor,
  };

  return (
    <footer>
      <div className="footer_wrapper" style={top_footer_style}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer_logo_div">
                <img
                  className="f_logo"
                  src={`./static/assets/images/${logo}`}
                  alt="logo"
                />
                <p>{footerSectionContent.logoParaText}</p>
                <div className="footer_img_wrapper">
                  <img src="./static/assets/images/ssl_cer.svg" alt="" />
                  <img src="./static/assets/images/ssl_sec.svg" alt="" />
                  <a
                    href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {olaimg === "" ? (
                      <img
                        src={`static/assets/images/${footerSectionContent.encrypt}`}
                        alt=""
                        className="incrypt"
                      />
                    ) : (
                      <img
                        src={olaimg}
                        alt=""
                        className="ola_logo"
                        width="40px"
                        height="40px"
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer_link">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link
                      to="/how-it-works"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/terms-of-use"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Terms of service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer_link">
                <h4>Important Links</h4>
                <ul>
                  <li>
                    <Link
                      to="/disclaimer"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/do-not-sell-my-information"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Do not sell my information
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/e-consent"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      E-consent
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/unsubscribe"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + footerSectionSty.linkColorHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + footerSectionSty.linkColor;
                      }}
                    >
                      Unsubscribe
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_text" style={bottom_footer_style}>
        <div className="container">
          <p>
            <p>{footerSectionContent.bottomParaText}</p>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
