import React, { useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiFormSelect } from "@mdi/js";
import { mdiLanConnect } from "@mdi/js";
import { mdiAccountCheckOutline } from "@mdi/js";
import { mdiPlay } from "@mdi/js";
import { HomeContext } from "../../App";
import RequestBtn from "../../component/RequestBtn/RequestBtn";

const Finding = ({ onButtonClick }) => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const findSectionContent = data.customization.findSection.content;
  const findSectionSty = data.customization.findSection.style;

  const ctaStyle = {
    backgroundColor: isHover ? findSectionSty.ctaHoverBg : findSectionSty.ctaBg,
    color: isHover
      ? findSectionSty.ctaHoverTextColor
      : findSectionSty.ctaTextColor,
  };

  const FindHeadingColor = {
    color: findSectionSty.headingColor,
  };
  const FindSubHeadingColor = {
    color: findSectionSty.subHeadingColor,
  };
  const FindIconColor = {
    color: findSectionSty.iconColor,
  };
  const FindBoldColor = {
    color: findSectionSty.boldTextColor,
  };
  const FindParaColor = {
    color: findSectionSty.paraTextColor,
  };
  const handleClick = () => {
    onButtonClick();
  };
  return (
    <div className="finding_loan_wrapper">
      <div className="container">
        <div
          className={`row align-items-lg-center ${
            findSectionContent.findSectionFlip === "Yes"
              ? "flex-row-reverse"
              : ""
          }`}
        >
          <div className="col-md-6">
            <div className="finding_loan_items">
              <div className="finding_loan_item">
                <Icon
                  style={{ color: findSectionSty.iconColor }}
                  path={mdiFormSelect}
                  size={1.5}
                />
                <h6 style={FindBoldColor}>{findSectionContent.icon1bold}</h6>
                <p style={FindParaColor}>{findSectionContent.icon1text}</p>
              </div>
              <div className="finding_loan_item">
                <Icon
                  style={{ color: findSectionSty.iconColor }}
                  path={mdiLanConnect}
                  size={1.5}
                />
                <h6 style={FindBoldColor}>{findSectionContent.icon2bold}</h6>
                <p style={FindParaColor}>{findSectionContent.icon2text}</p>
              </div>
              <div className="finding_loan_item">
                <Icon
                  style={{ color: findSectionSty.iconColor }}
                  path={mdiAccountCheckOutline}
                  size={1.5}
                />
                <h6 style={FindBoldColor}>{findSectionContent.icon3bold}</h6>
                <p style={FindParaColor}>{findSectionContent.icon3text}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="finding_loan_content">
              <h3
                style={FindHeadingColor}
                dangerouslySetInnerHTML={{
                  __html: findSectionContent.headingText,
                }}
              />
              <p
                style={FindSubHeadingColor}
                dangerouslySetInnerHTML={{
                  __html: findSectionContent.subheadingText,
                }}
              />
              <RequestBtn
                handleClick={handleClick}
                ctaText={findSectionContent.ctaText}
                ctaSubText={findSectionContent.ctaSubText}
                ctaStyle={ctaStyle}
                setIsHover={setIsHover}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finding;
