import React, { useEffect, useContext, useState } from "react";
import { HomeContext } from "../../App";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import RequestBtn from "../../component/RequestBtn/RequestBtn";

const HomeCTA = ({ onButtonClick }) => {
  const [isHover, setIsHover] = useState(false);
  const data = useContext(HomeContext);
  const ctaSectionContent = data.customization.ctaSection.content;
  const ctaSectionSty = data.customization.ctaSection.style;

  const CtaHeadingColor = {
    color: ctaSectionSty.headingColor,
  };
  const CtaSubHeadingColor = {
    color: ctaSectionSty.subHeadingColor,
  };
  const gradColor1 = ctaSectionSty.gradColor1;
  const gradColor2 = ctaSectionSty.gradColor2;
  const bgColor = {
    background: ctaSectionSty.backgroundColor
      ? ctaSectionSty.backgroundColor
      : `linear-gradient(104deg, ${gradColor1} 0%, ${gradColor2} 100%)`,
  };
  const ctaStyle = {
    backgroundColor: isHover ? ctaSectionSty.ctaHoverBg : ctaSectionSty.ctaBg,
    color: isHover
      ? ctaSectionSty.ctaHoverTextColor
      : ctaSectionSty.ctaTextColor,
  };
  const handleClick = () => {
    onButtonClick();
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        // Scrolling up, add class
        if (window.scrollY > 400) {
          document
            .querySelector(".footer_stickbar_wrapper")
            .classList.add("active_div");
        } else {
          document
            .querySelector(".footer_stickbar_wrapper")
            .classList.remove("active_div");
        }
      } else {
        // Scrolling down, remove class
        document
          .querySelector(".footer_stickbar_wrapper")
          .classList.remove("active_div");
      }

      // Update previous scroll position
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="page_cta_wrapper" style={bgColor}>
        <div className="container">
          <h3
            style={CtaHeadingColor}
            dangerouslySetInnerHTML={{
              __html: ctaSectionContent.headingText,
            }}
          />
          <p
            style={CtaSubHeadingColor}
            dangerouslySetInnerHTML={{
              __html: ctaSectionContent.subheadingText,
            }}
          />
          <RequestBtn
            handleClick={handleClick}
            ctaText={ctaSectionContent.ctaText}
            ctaSubText={ctaSectionContent.ctaSubText}
            ctaStyle={ctaStyle}
            setIsHover={setIsHover}
          />
        </div>
      </div>
      <div className="footer_stickbar_wrapper">
        <div className="container">
          <div className="footer_stickbar">
            <h4>Request a loan in few clicks</h4>
            <a
              className="request_btn"
              href="#"
              style={ctaStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className="submit_underline">Request Now</span> -{" "}
              <span className="submit_blur">It's free </span>{" "}
              <Icon path={mdiPlay} size={0.9} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCTA;
