import React, { useState, useContext } from "react";
import Header from "../../section/header";
import Footer from "../../section/footer";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { Link } from "react-router-dom";
import { PageContext } from "../../App";
import PageCTA from "../../section/page-cta";

const DoNotSell = () => {
  const pageData = useContext(PageContext);
  const ccpaData = pageData.ccpa
    ? JSON.parse(pageData.ccpa)
    : { heading: "", content: { text: "" } };
  const { heading, content } = ccpaData;
  return (
    <>
      <Header />
      <div className="content_page_wrapper">
        <div className="container">
          <ul className="page_breadcrumb">
            <li>
              <Link to="/">Home </Link>
            </li>
            <li>
              <Icon path={mdiChevronRight} size={1} /> Do Not Sell My
              Information
            </li>
          </ul>
          <h1>{heading}</h1>
          <div dangerouslySetInnerHTML={{ __html: content.text }} />
        </div>
      </div>
      <PageCTA />
      <Footer />
    </>
  );
};

export default DoNotSell;
